<template>
  <div>
    <h1>Tooted</h1>
    <v-data-table
        :headers="headers"
        :hide-default-footer=true
        :items="products"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="id"
        show-expand
        class="payments-table"
        :loading="loading"
        :items-per-page=20
        loading-text="Laeb... palun oota"
        show-select
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "Products",
  data () {
    return {
      expanded: [],
      singleExpand: false,
      payments: [],
      loading: false,
      headers: [
        { text: 'Nimi', align: 'start', value: 'name' },
        { text: 'Tootekood', value: 'reference' },
        { text: 'Hind', value: 'price' },
        { text: 'Kogus laos', value: 'qty' },
      ],
      page: 1,
      pagination: 0,
    }
  },
}
</script>

<style scoped>

</style>